import React, { useEffect, useState } from "react";
import LayoutNew from "../components/LayoutNew";
import { Helmet } from "react-helmet";
import axios from "axios";
import {calculateYearlyPricePerMonth, splitDescription} from "../utils";
import {productCatalogS3Bucket} from "../config"

import ServiceAddress from "./new-pages/common/ServiceAddress";
import HaveQuestion from "./new-pages/common/HaveQuestion";
import LastSection from "./new-pages/common/LastSection";
import MessageModal from "./new-pages/common/MessageModal";

import phoneImg1 from "../../static/images/phone-banner.png";
import phoneImg2 from "../../static/images/phone-stand.png";
import bubbleIcon from "../../static/images/speech-bubble.png";
import tv3Icon1 from "../../static/images/tv-sec-icon1.png";
import phone3Icon2 from "../../static/images/phone-sec-icon2.png";
import phone3Icon3 from "../../static/images/phone-sec-icon3.png";
import phone3Icon4 from "../../static/images/phone-sec-icon4.png";
import phone3Icon5 from "../../static/images/phone-sec-icon5.png";
import phone3Icon6 from "../../static/images/phone-sec-icon6.png";
import CommonQuestionPhone from "./new-pages/common/CommonQuestionPhone";

const FrontPhonePage = () => {
    const [messagePopup, setMessagePopup] = useState(false);
    const [tabType, setTabType] = useState(false);
    const [phones, setPhones] = useState([{
        id: 0,
        api_name: "Loading...",
        description: "\u2714\ufe0fLoading...",
        billing_period: [
            {
                monthly: {
                    api_name: "Loading...",
                    display_name: "Home Phone",
                    invoice_name: "Home Phone",
                    price: 10.00
                },
                yearly: {
                    api_name: "Loading...",
                    display_name: "Home Phone Yearly",
                    invoice_name: "Home Phone Yearly",
                    price: 108.00
                }
            }
        ],
        addons: [],
        resources: [],
        status: "ACTIVE"
    }]);


    useEffect(() => {
        const fetchData = async () => {
            try {
                const response = await axios(
                    productCatalogS3Bucket + "snapshots/latest/services/home-phones/plans.json"
                );
                setPhones(response.data);
            } catch (err) {
                console.error("Failed to fetch phone plans:", err);
            }
        };
        fetchData();
    }, []);

    return (
        <LayoutNew>
            <Helmet>
                <title>Phone – Purple Cow Internet 💜🐄</title>
            </Helmet>
            <section className="phone-section1">
                <h1 className="h2">Home phone, keep your existing number</h1>
                <img src={phoneImg1} alt="phone-banner" className="phone-banner-img" />
            </section>

            <section className="tv-section2 new-sec2">
                <div className="container mx-auto px-4">
                    <div className="grid sm:grid-cols-1 md:grid-cols-2 gap-4">
                        <div className="section2-text">
                            <h3 className="h3">Home phone with all the extra features but without the price</h3>
                            <p className="p2">We made sure all the features you love are included with your home phone without charging you for any addons. All you do is plug your existing handset into the package we mail you and you're done.</p>

                        </div>
                        <div className="">
                            <img src={phoneImg2} alt="phone-stand" className="tv-sec2-img" />
                        </div>
                    </div>
                </div>
            </section>

            <section className="tv-section3">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-white text-center">All the features</h2>
                    <div className="grid xs:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
                        <div className="text-center">
                            <img src={tv3Icon1} alt="Phone icon" className="tv-sec3-icon" />
                            <p className="p2 text-white">Set up is effortless. We'll port over your existing number and get you set up.</p>
                        </div>
                        <div className="text-center">
                            <img src={phone3Icon2} alt="Phone icon" className="tv-sec3-icon" />
                            <p className="p2 text-white">Free long distance in North America.* No need to worry about getting hit with unexpected long distance charges.</p>
                        </div>
                        <div className="text-center">
                            <img src={phone3Icon3} alt="Phone icon" className="tv-sec3-icon" />
                            <p className="p2 text-white">Automatically blocks robo calls. Our integration identifies and blocks known robocallers and telemarketers, protecting you from fraud, scams, and annoying unsolicited calls.</p>
                        </div>
                        <div className="text-center">
                            <img src={phone3Icon4} alt="Phone icon" className="tv-sec3-icon" />
                            <p className="p2 text-white">Call forwarding if you are away and want to send your calls to another phone.</p>
                        </div>
                        <div className="text-center">
                            <img src={phone3Icon5} alt="Phone icon" className="tv-sec3-icon" />
                            <p className="p2 text-white">Three way calling to connect the whole family.</p>
                        </div>
                        <div className="text-center">
                            <img src={phone3Icon6} alt="Phone icon" className="tv-sec3-icon" />
                            <p className="p2 text-white">Call display so you always know who's calling.</p>
                        </div>
                    </div>
                </div>
            </section>

            <section className="tv-section4 mt-20">
                <div className="container mx-auto px-4">
                    <h2 className="h2 text-secondary text-center">All the features, one low price</h2>
                    <p className="p2 text-black text-center mb-8">Home phone price can't be beat!</p>
                    <div className="tab-type new-tab">
                        <span onClick={() => setTabType(false)} className={tabType ? "tab-type__option " : "tab-type__option active"}>Pay Monthly</span>
                        <span onClick={() => setTabType(true)} className={tabType ? "tab-type__option active" : "tab-type__option "}>Pay Yearly</span>
                    </div>
                    <div className="selectPlan addon-plan-1">
                        {phones.map((phone, index) => (
                            phone.status != "ACTIVE" ? null : (
                                <div className="selectPlan__optional" key={index}>
                                    <label className="selectPlan__box phone-page">
                                        <input
                                            type="checkbox"
                                            name="selectPlan__"
                                            checked={false}
                                        />
                                        <div className="selectPlan__box-inner">
                                            <h3 className="h3">{phone.billing_period[0].monthly.display_name}</h3>
                                            <h2 className="h2" dangerouslySetInnerHTML={{ __html: !tabType ? '$' + phone.billing_period[0].monthly.price + ' <sup>/ month</sup>' : '<del class="delete-price">$' + phone.billing_period[0].monthly.price + '</del> $'+ calculateYearlyPricePerMonth(phone.billing_period[0].yearly.price) +' <sup>/ month</sup>'}}>
                                            </h2>
                                            <ul className="plan-list">
                                                {splitDescription(phone.description).map((line, index) => (
                                                    <li key={index}>{line}</li>
                                                ))}
                                            </ul>
                                        </div>
                                    </label>
                                </div>
                            )
                        ))}
                    </div>
                    <p className="p2 bottom">One time phone number port or new number charge of $55. Shipping included
                        free.</p>
                </div>
            </section>

            <ServiceAddress title="Check your service address"/>

            <CommonQuestionPhone/>

            <HaveQuestion closepopup={setMessagePopup}/>

            <LastSection title="Or if you're ready..."/>

            <div className="contact-us-parent">
                <div className="contact-click-box" onClick={() => setMessagePopup(true)}>
                    <img src={bubbleIcon} alt="bubble" className="bubble-icon"/>
                </div>
            </div>
            {messagePopup == true ? (<MessageModal closepopup={setMessagePopup}></MessageModal>) : null}
        </LayoutNew>
    )
}

export default FrontPhonePage